<template>
  <div v-if="!error" class="blog-latest">
    <h2
      class="ma-0 ms-3 ms-lg-0 me-3"
    >
      {{ $t('nav.blog') }}
    </h2>
    <h3
      class="ma-0 ms-3 ms-lg-0 me-3 mb-3 subtitle-1 grey--text text--darken-2"
    >
      {{ $t('home.introBlogDesc') }}
    </h3>
    <!-- todo loader -->

    <v-slide-group
      v-if="!loading"
      class="ms-3 ms-lg-0"
      :class="{ 'desktop': !isMobile }"
    >
      <v-slide-item
        v-for="post in latestPosts"
        :key="post.id"
        class="d-block d-inline-block single-post me-3"
        :class="{ 'mobile': isMobile }"
      >
        <v-card
          height="100%"
          flat
        >
          <a
            :href="post.link"
            target="_blank"
            rel="noopener"
          >
            <v-img
              :alt="post.title.rendered"
              :src="post.img"
              aspect-ratio="1"
              max-height="270"
            />
            <p class="pa-3 post-title mb-0" v-html="post.title.rendered" />
          </a>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import { asyncForEach } from '@/utils'

export default {
  name: 'BlogLatest',

  data () {
    return {
      blogUrl: process.env.BLOG_URL,
      error: false,
      loading: true,
      latestPosts: []
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },

  mounted () {
    this.getLatest()
  },

  methods: {
    getLatest () {
      this.loading = true
      this.$axios.$get(`${this.blogUrl}/wp-json/wp/v2/posts?per_page=4&_fields=id,title,link,featured_media,date`).then(async (posts) => {
        await asyncForEach(posts, async (post) => {
          const media = await this.$axios.$get(`${this.blogUrl}/wp-json/wp/v2/media/${post.featured_media}?_fields=media_details`)
          post.img = media.media_details.sizes['post-thumbnail'].source_url
        })
        this.latestPosts = posts
        this.loading = false
        this.$emit('loaded')
      }).catch((e) => {
        this.error = true
        this.$rollbar.error(e)
        this.loading = false
        this.$emit('loaded')
      })
    }
  }
}
</script>
<style lang="scss">
.blog-latest {
  .v-item-group {
    &.desktop {
      .v-slide-group__content {
        width: 100%!important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.blog-latest {
  .v-skeleton-loader {
    border-radius: $standard-radius;
  }
  a {
    &:hover {
      text-decoration: none;
      opacity: 0.9;
      border-radius: $standard-radius;
      .v-image {
        opacity: 0.8;
      }
    }
  }
  .single-post{
    width: 25%;
    .v-image {
      border-radius: $standard-radius;
    }

    &.mobile {
      width: 270px;
    }
    .post-title {
      background: $white-color;
      z-index: 4;
      position: relative;
      margin-top: -10px;
      border-radius: $standard-radius;
    }
  }
}
</style>
