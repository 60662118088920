<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" lg="5" class="pt-0 pt-lg-5">
        <Breadcrumbs
          v-if="!isMobile"
          :items="breadcrumbs.items"
          class="hidden-md-and-down"
        />
        <h1
          class="pa-2 text-md-h5 text-h6 text-center text-sm-start font-weight-bold"
        >
          {{ $t('home.h1', { market: currentMarket.countryName }) }}
        </h1>
        <LazyHydrate v-if="isMobile" when-visible>
          <Categories class="mt-0 mt-lg-7 me-lg-3" />
        </LazyHydrate>

        <LazyHydrate when-visible>
          <SortingButtons
            :filter="''"
            :sorting-option="''"
            class="ma-0 mt-5 mb-3 hidden-lg-and-up"
            @update-sorting="goToListing"
          />
        </LazyHydrate>
      </v-col>
      <v-col v-if="!isMobile" class="hidden-md-and-down" cols="4">
        <NewConsultationCta
          class="large mt-5 me-3"
          align="end"
          source="list-top"
        />
      </v-col>
      <v-col v-if="!isMobile" class="hidden-md-and-down" cols="3" />
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" lg="9">
        <LazyHydrate v-if="!isMobile" when-visible>
          <Categories class="mt-0 mt-lg-7 me-lg-3" />
        </LazyHydrate>
        <LazyHydrate :on-interaction="['mouseover', 'touchstart']">
          <GotAQuestionCTA
            class="ma-0 mt-6 hidden-lg-and-up"
            source="home-page-banner"
          />
        </LazyHydrate>
        <LazyHydrate v-if="!isMobile" when-visible>
          <SortingButtons
            :filter="''"
            :sorting-option="''"
            class="ma-0 mt-9 hidden-md-and-down"
            @update-sorting="goToListing"
          />
        </LazyHydrate>

        <lazyHydrate never>
          <ListTitle type="recentlyAnswered" />
        </lazyHydrate>
        <LazyHydrate when-visible>
          <List
            type="recentlyAnswered"
            @loaded="recentlyAnsweredLoading = false"
          />
        </LazyHydrate>

        <div v-if="currentMarket.paymentMethods.length">
          <lazyHydrate never>
            <ListTitle type="urgent" />
          </lazyHydrate>
          <LazyHydrate :when-visible="{ rootMargin: '200px' }">
            <List type="urgent" @loaded="urgentLoading = false" />
          </LazyHydrate>

          <lazyHydrate never>
            <ListTitle type="secret" />
          </lazyHydrate>

          <LazyHydrate :when-visible="{ rootMargin: '200px' }">
            <List type="secret" @loaded="secretLoading = false" />
          </LazyHydrate>
        </div>

        <lazyHydrate never>
          <ListTitle type="recentlyAsked" />
        </lazyHydrate>

        <LazyHydrate :when-visible="{ rootMargin: '200px' }">
          <List type="recentlyAsked" @loaded="recentlyAskedLoading = false" />
        </LazyHydrate>

        <LazyHydrate
          v-for="categoryItem in categoryItems"
          :key="categoryItem.id"
          :when-visible="{ rootMargin: '200px' }"
        >
          <div>
            <ListTitle
              class="mb-2"
              type="category"
              :category-id="categoryItem.id"
              :category-slug="categoryItem.slug"
            />

            <List
              type="category"
              :category-id="categoryItem.id"
              @category-loaded="setCategoryLoaded"
            />
          </div>
        </LazyHydrate>

        <NewConsultationCta
          v-if="!isMobile"
          class="hidden-md-and-down large mt-12 mb-12"
          align="center"
          source="home-bottom"
        />
        <LazyHydrate when-visible>
          <BlogLatest class="mt-10" @loaded="blogLatestLoading = false" />
        </LazyHydrate>
        <LazyHydrate v-if="blogLatestLoading" never>
          <Placeholder type="blog" />
        </LazyHydrate>
      </v-col>

      <v-col cols="12" lg="3">
        <Stats v-if="!isMobile" class="hidden-md-and-down ma-0 mt-6 ms-lg-6" />
        <GotAQuestionCTA
          v-if="!isMobile"
          class="ma-0 mt-9 ms-lg-9 hidden-md-and-down"
          source="home-page-banner"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { asyncForEach } from '@/utils'
import Breadcrumbs from '@/components/Breadcrumbs'
import List from '@/components/ConsultationsListingComponents/List'
import Stats from '@/components/Stats'
import NewConsultationCta from '@/components/NewConsultationCta'
import GotAQuestionCTA from '@/components/GotAQuestionCTA'
import Placeholder from '@/components/ConsultationsListingComponents/Placeholder'
import ListTitle from '@/components/ConsultationsListingComponents/ListTitle'
import SortingButtons from '@/components/ConsultationsListingComponents/SortingButtons'
import Categories from '@/components/ConsultationsListingComponents/Categories'
import BlogLatest from '@/components/BlogLatest'

export default {
  auth: false,
  name: 'HomePage',

  components: {
    LazyHydrate,
    Breadcrumbs,
    Placeholder,
    Categories,
    ListTitle,
    List,
    Stats,
    SortingButtons,
    GotAQuestionCTA,
    BlogLatest,
    NewConsultationCta
  },

  async asyncData ({ app, $dayjs }) {
    const currentMarket = app.i18n
      .t('markets')
      .filter(i => i.country === app.i18n.locale.substring(3, 5))[0]
    const categoryItems = []

    $dayjs.locale(app.i18n.locale.substring(0, 2))

    await asyncForEach(app.i18n.t('categories'), (category) => {
      categoryItems.push({
        id: category.id,
        slug: category.slug
      })
    })

    return {
      pageTitle: `${app.i18n.t('globals.siteName')} ${
        currentMarket.countryName
      } — ${app.i18n.t('home.title')}`,
      pageDescription: `${app.i18n.t('home.description', {
        market: currentMarket.countryName
      })}`,
      h1Description: `${app.i18n.t('home.h1', {
        market: currentMarket.countryName
      })}`,
      breadcrumbs: {
        items: [
          {
            text: app.i18n.t('home.h1', {
              market: currentMarket.countryName
            }),
            disabled: true
          }
        ]
      },
      currentMarket,
      recentlyAnsweredLoading: true,
      urgentLoading: true,
      secretLoading: true,
      recentlyAskedLoading: true,
      blogLatestLoading: true,
      category1Loaded: false,
      category2Loaded: false,
      category3Loaded: false,
      category4Loaded: false,
      category5Loaded: false,
      category6Loaded: false,
      category7Loaded: false,
      category8Loaded: false,
      categoryItems
    }
  },

  head () {
    return {
      htmlAttrs: {
        dir: this.$t('meta.dir')
      },
      title: `${this.numberOfUnseenNotifications}${this.pageTitle}`,
      meta: [
        { hid: 'og:title', property: 'og:title', content: `${this.pageTitle}` },
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageDescription
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${process.env.SITE_URL}/img/siteimage.jpg`
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: `${process.env.SITE_URL}/img/siteimage.jpg`
        }
      ]
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    numberOfUnseenNotifications () {
      const unseen = this.$store.state.notificationsResponse.data.filter(
        i => i.seen === false
      ).length
      return unseen > 0 ? `(${unseen}) ` : ''
    }
  },

  created () {
    this.$store.commit('updateBreadcrumbs', this.breadcrumbs.items)
  },

  methods: {
    goToListing (filter, value, redirect, search) {
      this.$router.push(
        this.localePath({
          name: 'consultations-filter-type',
          query: { sort: value }
        })
      )
    },
    setCategoryLoaded (id) {
      switch (id) {
        case 1:
          this.category1Loaded = true
          break
        case 2:
          this.category2Loaded = true
          break
        case 3:
          this.category3Loaded = true
          break
        case 4:
          this.category4Loaded = true
          break
        case 5:
          this.category5Loaded = true
          break
        case 6:
          this.category6Loaded = true
          break
        case 7:
          this.category7Loaded = true
          break
        case 8:
          this.category8Loaded = true
          break
      }
    }
  }
}
</script>
