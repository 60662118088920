<template>
  <div
    class="mt-0 grey--text text--darken-1 caption answered-by-box"
  >
    {{ $t('consultation.answered') }} <span v-if="linked">{{ localTime }}</span> {{ $t('consultation.by') }}
    <nuxt-link
      v-if="linked"
      :to="localePath({ name: model.user.role !== 'user' ? 'experts-id-username' : 'users-id-username', params: { id: model.user.id, username: model.user.username }})"
    >
      {{ model.user.username }}
    </nuxt-link>
    <span v-else>
      {{ model.user.username }}
    </span>
    <i
      v-if="model.user.role === 'expert' || model.user.role === 'admin'"
      class="blue--text v-icon icon-12 icon-shield-check"
    />
    <i
      v-if="model.user.role === 'ai'"
      class="yellow--text text--darken-2 v-icon icon-12 icon-shield-check"
    />
  </div>
</template>

<script>
export default {
  name: 'LastAnswer',

  props: {
    model: {
      type: Object,
      required: true
    },
    linked: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      localTime: this.$dayjs.utc(this.model.created_at).local().fromNow()
    }
  }

}
</script>

<style lang="scss" scoped>
.answered-by-box{
  z-index: 2;
  position:relative;
}
</style>
