<template>
  <v-card
    flat
    class="pa-4 pb-6 ma-0 ms-lg-3 cta-container"
  >
    <v-row
      no-gutters
    >
      <v-col
        cols="6"
        lg="12"
        class="text-center"
      >
        <img
          src="/img/got-a-question.svg"
          class="ms-1 me-1 mt-3 mt-lg-0 d-inline-block"
          :alt="$t('consultation.gotAQuestionTitle')"
        >
      </v-col>
      <v-col
        cols="6"
        lg="12"
        class="d-flex align-center"
      >
        <div
          class="cta-content"
        >
          <h3 class="ma-0 mt-lg-3">
            {{ $t('consultation.gotAQuestionTitle') }}
          </h3>
          <h4
            class="ma-0 mt-2 subtitle-1 grey--text text--darken-2"
          >
            {{ $t('consultation.gotAQuestionTitleDesc') }}
          </h4>
          <NewConsultationCta class="mt-4" :source="source" />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NewConsultationCta from '@/components/NewConsultationCta'

export default {
  name: 'GotAQuestionCTA',

  components: {
    NewConsultationCta
  },

  props: {
    source: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cta-container {
  border-radius: $standard-radius!important;
  .cta-content {
    margin: 0 auto;
    text-align: center;
  }
  img {
    width: 164px;
    height: 152px;
  }
}
</style>
