<template>
  <v-slide-group
    class="questions-slider ms-3 ms-lg-0 me-lg-3"
  >
    <v-slide-item
      v-for="(n, i) in 3"
      :key="i"
      height="100%"
      class="d-inline-block single-item me-3"
      :class="{ 'desktop': !isMobile }"
    >
      <v-card height="100%" flat class="pa-3">
        <div>
          <div class="ma-0 pa-10 box grey lighten-3" />
          <v-row no-gutters>
            <v-col cols="12">
              <div class="ma-0 mt-3 pa-4 box grey lighten-4" />
              <div class="ma-0 mt-2 pa-2 me-3 box grey lighten-5" />
              <div class="ma-0 mt-2 pa-2 me-2 box grey lighten-5" />
              <div class="ma-0 mt-2 pa-2 me-12 box grey lighten-5" />
              <div class="ma-0 mt-2 pa-2 me-6 box grey lighten-5" />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  name: 'Placeholder',

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  border-radius: $standard-radius;
}
.single-item {
  width: 270px;
  max-width: 270px;
  &.desktop {
    width: 370px !important;
    max-width: unset;
  }
}
.v-card {
  border-radius: $standard-radius;
}
</style>
