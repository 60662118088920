<template>
  <v-slide-group
    class="options-slider"
  >
    <LazyHydrate
      v-for="(option, i) in getAvailableSortingOptions"
      :key="option.name"
      :on-interaction="['mouseover','touchstart']"
    >
      <v-slide-item>
        <v-btn
          depressed
          class="white black--text ms-3"
          :class="{ 'me-3 me-lg-0': i === getAvailableSortingOptions.length - 1, 'ms-lg-0': i === 0 }"
          @click="updateSorting(option.id)"
        >
          <strong>
            <i :class="`me-1 v-icon icon-12 icon-${option.icon}`" />
            {{ option.name }}
          </strong>
        </v-btn>
      </v-slide-item>
    </LazyHydrate>
  </v-slide-group>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { trackEvent } from '@/utils/tracking'

export default {
  name: 'SortingButtons',
  components: {
    LazyHydrate
  },

  props: {
    filter: {
      type: String,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    getAvailableSortingOptions () {
      const options = []
      this.$t('sortingOptions').forEach((option) => {
        if (option.id === 'most-visited') {
          if (this.$auth.loggedIn && this.$auth.user.role !== 'user') {
            options.push(option)
          }
        } else {
          options.push(option)
        }
      })
      return options
    }
  },

  methods: {
    updateSorting (value) {
      trackEvent(
        'Sorted questions',
        { sort_from: this.sortingOption, sort_to: value },
        this.$auth.user
      )
      this.$emit('update-sorting', this.filter, value, 1, this.search)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-btn {
  &:hover {
    text-decoration: none;
    color: $mahkamaty-blue-color-alt !important;
  }
}
.v-slide-group__content {
  text-align: left;
  display: inline-block;
}
</style>
