<template>
  <v-list-item-avatar
    :class="boxClass"
    tile
    width="100%"
    height="90"
    class="ma-0 pa-0"
  >
    <div
      v-if="linking"
    >
      <nuxt-link
        :to="localePath({ name: 'consultations-id-slug', params: { id: model.id, slug: model.slug } })"
        class="pa-0"
      >
        <span
          class="text-h6"
        >
          <strong>{{ model.answers_count }}</strong>
        </span>
        <small class="d-block"><strong>{{ $t(getProperLabel(model.answers_count, 'Answers')) }}</strong></small>
      </nuxt-link>
    </div>
    <div
      v-else
    >
      <span
        class="text-h6"
      >
        <strong>{{ model.answers_count }}</strong>
      </span>
      <small class="d-block"><strong>{{ $t(getProperLabel(model.answers_count, 'Answers')) }}</strong></small>
    </div>
  </v-list-item-avatar>
</template>

<script>
import { getProperLabel } from '@/utils'

export default {
  name: 'AnswersCount',

  props: {
    model: {
      type: Object,
      required: true
    },
    linking: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data () {
    return {
      getProperLabel
    }
  },

  computed: {
    boxClass () {
      if ((this.model.accepted_answer_id && this.model.accepted_answer_id !== 0) || this.model.has_safe_answer) {
        return 'accepted-expert'
      } else if (this.model.answers_count === 0) {
        return 'no-answers'
      } else {
        return 'has-answers'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-avatar {
    a:hover {
      text-decoration: none;
    }
    border-radius: $standard-radius!important;
    &.accepted-expert {
      background: $light-green-color;
      color: $green-color;
      border: none;
      a {
        color: $green-color;
      }
    }
    &.no-answers {
      background: $light-red-color;
      color: $red-color;
      a {
        color: $red-color;
      }
    }
    &.has-answers {
      border: 1px solid $mahkamaty-blue-color;
      color: $mahkamaty-blue-color;
      a {
        color: $mahkamaty-blue-color;
      }
    }
  }

  .bluetext {
    color: $mahkamaty-blue-color;
  }
</style>
